import {
  EventDateRangeOptions,
  FormatDateOptions,
  FormatType,
  ZonedDate,
} from "@flicket/utils";
import { i18n } from "~lib/i18n";

export function formatEventDateRange(
  startDate: string,
  endDate: string,
  options: EventDateRangeOptions
) {
  return ZonedDate.formatEventRange(startDate, endDate, {
    format: "basicShort",
    ...options,
    timeZone: options.timeZone ?? i18n.timezone,
    locale: options.locale ?? i18n.locale,
  });
}

export function formatEventDate(
  date: string,
  type: FormatType = "date",
  options: FormatDateOptions
) {
  return ZonedDate.format(date, type, {
    ...options,
    timeZone: options.timeZone ?? i18n.timezone,
    locale: options.locale ?? i18n.locale,
  });
}

export function formatOrgDate(
  date: string,
  type: FormatType = "date",
  options?: Partial<FormatDateOptions>
) {
  return ZonedDate.format(date, type, {
    ...options,
    timeZone: i18n.timezone,
    locale: i18n.locale,
  });
}
