import { Stack, Box, Link, Text, SystemProps, system } from "flicket-ui";

import styled from "styled-components";
import NextImage from "next/future/image";
import { Swiper, SwiperSlide } from "swiper/react";

import { Event } from "~graphql/sdk";
import { getImageSrc } from "~lib/helpers";
import { FormatDateOptions } from "@flicket/utils";
import { useOrganization } from "~hooks";
import { i18n } from "~lib";
import { formatEventDate } from "~lib/helpers/formatDate";

interface FeaturedEventProps extends SystemProps {
  events: Partial<Event>[];
  sliderParams?: any;
  slidesPerView?: number | string;
  breakpoints?: object;
}

const StyledSwiper = styled(Box)`
  .swiper {
    margin-right: -16px;
    margin-left: -16px;

    padding-right: 16px;
    padding-left: 16px;
  }

  ${system}
`;

const StyledFeaturedEvent = styled.div`
  position: relative;
  width: 100%;
  height: 160px;

  overflow: hidden;

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  border-radius: 12px;
  transition: box-shadow 0.9s ease-out;

  &:hover {
    opacity: 1;
    box-shadow: ${(p) => p.theme.shadows.hover};
  }

  .card-background::after {
    content: "";

    position: absolute;
    width: 100%;
    height: 100%;

    background: linear-gradient(
      62.53deg,
      #1d1c20 0%,
      rgba(29, 28, 32, 0.25) 100%
    );
  }

  @media (min-width: ${(p) => p.theme.breakpoints.md}) {
    height: 200px;
  }
`;

const EventInfo = styled((props) => <Box {...props} />)`
  position: absolute;
  bottom: 12px;
  left: 12px;
  max-width: calc(100% - 24px);

  color: ${(p) => p.theme.colors.white};
`;

const EventDate = styled((props) => <Box {...props} />)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 0;
  left: 0;

  min-width: 52px;
  min-height: 69px;

  padding: 8px 16px;

  background-color: ${(p) => p.theme.colors.S300};

  color: ${(p) => p.theme.colors.white};

  border-radius: 0px 0 12px 0;
`;

const StyledLink = styled(Link)`
  position: absolute;
  width: 100%;
  height: 100%;

  z-index: ${(p) => p.theme.zIndices.docked};
`;

const StyledContainer = styled(Box)`
  > div {
    margin-right: ${(p) => p.theme.space[5]}px;
  }

  > div:last-of-type {
    margin-right: 0;
  }
`;

export const FeaturedEvents = ({
  events,
  slidesPerView = 1.5,
  sliderParams,
  ...props
}: FeaturedEventProps) => {
  const params = {
    spaceBetween: 16,
    watchOverflow: true,
    slidesPerView: slidesPerView,
    rebuildOnUpdate: true,
    ...sliderParams,
  };

  const FeaturedEvent = ({
    event,
  }: {
    event: FeaturedEventProps["events"][number];
  }) => {
    const { id, thumbnail, startDate, endDate, title, venue } = event;
    const { hasFeature } = useOrganization();

    const dateOptions: FormatDateOptions = {
      showStartTime: !hasFeature("hideEventTime"),
      // TODO: remove fallbacks when backfill complete
      timeZone: venue?.timezone ?? i18n.timezone,
      locale: venue?.locale ?? i18n.locale,
    };

    return (
      <StyledFeaturedEvent>
        <Box
          position="absolute"
          left="0"
          right="0"
          top="0"
          bottom="0"
          className="card-background"
        >
          {getImageSrc(thumbnail) && (
            <NextImage
              src={getImageSrc(thumbnail)}
              fill={true}
              alt={`${title} ${venue.name} featured image`}
              priority={true}
              quality={75}
              style={{ objectFit: "cover", objectPosition: "center" }}
            />
          )}
        </Box>
        <StyledLink to="/events/[eventId]" linkAs={`/events/${id}`} />
        <EventDate>
          <Stack direction="vertical" gap={0} alignItems="center">
            <Text variant="header.M" color="white">
              {formatEventDate(startDate, "custom", {
                day: "numeric",
                ...dateOptions,
              })}
            </Text>
            <Text variant="regular" textTransform="uppercase" color="white">
              {formatEventDate(startDate, "custom", {
                month: "short",
                ...dateOptions,
              })}
            </Text>
          </Stack>
        </EventDate>
        <EventInfo>
          <Text color="white" variant="header.S">
            {title}
          </Text>
          <Text color="white" variant="regular" ellipsis>
            {`${venue?.name}, ${
              venue?.address?.city ? venue?.address?.city : ""
            }`}
          </Text>
        </EventInfo>
      </StyledFeaturedEvent>
    );
  };

  return (
    <>
      <StyledSwiper display={{ md: "none" }}>
        <Swiper {...params} enableRein>
          {events?.map((event) => (
            <SwiperSlide key={event.id}>
              <FeaturedEvent event={event} />
            </SwiperSlide>
          ))}
        </Swiper>
      </StyledSwiper>
      <StyledContainer display={{ _: "none", md: "flex" }} {...props}>
        {events?.map((event) => (
          <FeaturedEvent key={event.id} event={event} />
        ))}
      </StyledContainer>
    </>
  );
};
