import React, { useEffect, FC } from "react";
import { NextRouter, useRouter } from "next/router";
import posthogJS from "posthog-js";
import { PostHogProvider as PostHogActualProvider } from "posthog-js/react";
import { analyticsEnabled, posthogApiKey, posthogHostUrl } from "~config";
import { useOrganization } from "~hooks";

// Initialize PostHog if we're in a browser runtime and it's enabled
if (typeof window !== "undefined" && analyticsEnabled) {
  posthogJS.init(posthogApiKey, {
    api_host: "/ingest",
    ui_host: posthogHostUrl,
    autocapture: false,
    // We're handling pageviews manually in the AnalyticsProvider'
    capture_pageview: false,
    capture_pageleave: false,
    loaded: (posthog) => {
      // debug mode in development
      if (process.env.NODE_ENV === "development") posthog.debug();
    },
  });
}

const parseQueryParams = (url: string): Record<string, string> => {
  const queryParams: Record<string, string> = {};
  const urlSearchParams = new URLSearchParams(
    new URL(url, window.location.origin).search
  );

  urlSearchParams.forEach((value, key) => {
    queryParams[key] = value;
  });

  return queryParams;
};

const AnalyticsProvider: FC = ({ children }) => {
  const { organization } = useOrganization();
  const router: NextRouter | null =
    typeof window !== "undefined" ? useRouter() : null;

  useEffect(() => {
    if (!router || !posthogJS) return;

    // Only capture enabled feature flags
    const featureFlags = organization?.features?.reduce((acc, feature) => {
      acc[feature] = true;
      return acc;
    }, {} as Record<string, boolean>);

    const handlePageView = (
      path: string,
      { shallow }: { shallow: boolean }
    ) => {
      // Grab the full URL, including the origin/domain
      const url = new URL(path, window.location.origin).toString();

      posthogJS.capture("$pageview", {
        $current_url: url,
        distinct_id: posthogJS.get_distinct_id(),
        flicket_organization_id: organization?.id,
        flicket_organization_name: organization?.name,
        flicket_organization_slug: organization?.slug,
        flicket_platform: "customer_portal",
        flicket_feature_flags: featureFlags,
        queryParams: parseQueryParams(path),
        shallow,
      });
    };

    const handlePageLeave = () => {
      posthogJS.capture("$pageleave", {
        $current_url: window.location.href,
        distinct_id: posthogJS.get_distinct_id(),
        flicket_organization_id: organization?.id,
        flicket_organization_name: organization?.name,
        flicket_organization_slug: organization?.slug,
        flicket_platform: "customer_portal",
        flicket_feature_flags: featureFlags,
        queryParams: parseQueryParams(window.location.href),
      });
    };

    router.events.on("beforeHistoryChange", handlePageLeave);
    router.events.on("routeChangeStart", handlePageView);
    return () => {
      router.events.off("beforeHistoryChange", handlePageLeave);
      router.events.off("routeChangeStart", handlePageView);
    };
  }, [router]);

  return (
    <PostHogActualProvider client={posthogJS}>{children}</PostHogActualProvider>
  );
};

export { AnalyticsProvider };
